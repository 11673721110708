<template>
  <div class="submitOrder" :class="{ isFull: isFullScreen }">
    <div class="submitOrder-header">
      <div class="submitOrder-title">确认订单</div>
      <div class="submitOrder-subtitle">提交之后将无法更改</div>
    </div>

    <div class="submitOrder-content">
      <div @click="toAddressList" class="userInfo">
        <div class="userInfo-spotBox">
          <img src="@/assets/images/icon/spot.png" class="userInfo-spot" />
        </div>

        <div class="userInfo-content">
          <div v-if="orderAdress&&orderAdress.consignee" class="userInfo-info">
            <div class="userInfo-name">{{ orderAdress.consignee }}</div>
            <div class="userInfo-phone">{{ orderAdress.formatPhone }}</div>
            <img
              src="@/assets/images/icon/arrow-right-grey02.png"
              class="userInfo-arrow"
            />
          </div>
          <div v-else class="userInfo-info">
            <div class="userInfo-name">请选择地址</div>
            <div class="userInfo-phone">{{ orderAdress.formatPhone }}</div>
            <img
              src="@/assets/images/icon/arrow-right-grey02.png"
              class="userInfo-arrow"
            />
          </div>

          <div class="userInfo-area">
            {{ orderAdress.province }} {{ orderAdress.city }}
            {{ orderAdress.district }} {{ orderAdress.street }}
          </div>

          <div v-if="orderAdress.isDefault" class="userInfo-default">
            [默认地址]
          </div>
        </div>
      </div>

      <div class="submitOrder-product">
        <div
          v-for="(item, index) in orderGiftsFilter"
          :key="index"
          class="product-item"
        >
          <div class="product-imgBox">
            <img :src="item.image" class="product-img" />
          </div>

          <div class="product-content">
            <div class="product-main">
              <div class="product-name">{{ item.giftName }}</div>
              <div class="product-params">
                {{ item.specString }} X{{ item.count }}
              </div>
            </div>

            <div class="product-bottom">
              <div @click="showProductModal(index)" class="editBtn-box">
                <img src="@/assets/images/icon/edit.png" class="editBtn-img" />
                <div class="editBtn-txt">编辑</div>
              </div>

              <div class="product-points">
                <div class="product-txt1">{{ item.totalPrice }}</div>
                <div class="product-txt2">积分</div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="orderGifts.length > 2"
          @click="toggleMore"
          class="product-more"
        >
          <template v-if="isFold">
            展开全部 {{ orderGifts.length }}件 商品
          </template>
          <template v-else>收起</template>
        </div>
      </div>

      <div class="submitOrder-expressFee">
        <div class="expressFee-title">运费：</div>
        <div class="expressFee-content">
          <span class="expressFee-points">{{ expressFee }}</span> 积分
        </div>
      </div>
    </div>

    <div class="submitOrder-content">
      <div class="provide-header" @click="onCreateHospital">
        <div class="provide-title">
          拜访发放计划
          <span v-if="hasPlan"
            >（{{ planModalState.selectedHospitalList.length }}家医院）</span
          >
        </div>
        <div class="provide-btn">+增加医院</div>
      </div>

      <div v-if="hasPlan">
        <div
          v-for="(item, i) in planModalState.selectedHospitalList"
          :key="'hospital_' + i"
          class="provide-content"
          @click="onEditHospital(i)"
        >
          <div class="provide-txt">拜访医院：{{ item.hospital }}</div>
          <div class="provide-txt">拜访科室：{{ item.depart }}</div>
          <div class="provide-txt">拜访人数：{{ item.estimatesNumber }}</div>
          <img
            class="provide-delete"
            src="@/assets/images/icon/provide-edit.png"
          />
        </div>
      </div>
      <div v-else class="provide-tip">
        <span>小贴士：</span>请如实填写拜访发放计划
      </div>
    </div>

    <div class="submitOrder-footer">
      <div class="footer-total">共{{ totalGiftCounts }}件</div>
      <div class="footer-info">
        <div class="footer-summary">
          <div class="footer-txt1">合计：</div>
          <div class="footer-txt2">{{ totalPrice }}</div>
          <div class="footer-txt3">积分</div>
        </div>

        <div class="footer-available">可用积分：{{ myPoints }}</div>
      </div>

      <div class="footer-btnWrap">
        <div @click="checkSubmit" class="btn">提交</div>
        <!-- <div @click="onCancel" class="btn btn-purple-border">取消</div> -->
      </div>
    </div>
  </div>

  <!-- 编辑拜访发放计划 -->
  <div v-if="planModalState.isShowPlanModal" class="plan-modal">
    <div class="plan-content">
      <div class="plan-title" v-if="planModalState.mode === 'create'">
        增加拜访医院
      </div>
      <div class="plan-title" v-else>编辑拜访医院</div>
      <div class="plan-row">
        <div class="row-name">省/市</div>
        <div @click="planModalState.isShowCityPicker = true" class="row-input">
          <div v-if="planModalState.province === ''" class="row-placeholder">
            请选择医院所在省/市
          </div>
          <div v-else class="row-content">
            {{ planModalState.province }} {{ planModalState.city }}
          </div>
          <img
            src="@/assets/images/icon/arrow-right-grey.png"
            class="row-arrow"
          />
        </div>
      </div>
      <div class="plan-row">
        <div class="row-name">医院</div>
        <div class="row-input">
          <input
            class="row-content row-field"
            @touchstart="checkHospitalAvailable"
            @input="inputHospital"
            v-model.trim="planModalState.hospital"
            type="text"
            placeholder="请输入医院名称关键词"
            maxlength="50"
            :disabled="isHospitalDisabled"
          />
          <img
            src="@/assets/images/icon/arrow-right-grey.png"
            class="row-arrow"
          />

          <div
            v-if="planModalState.isShowHospitalDropdown"
            class="row-dropdown"
          >
            <div
              v-for="item in planModalState.hospitalFilter"
              :key="item.id"
              @click="onHospitalDropdown(item)"
              class="dropdown-item"
            >
              {{ item.value }}
            </div>

            <div
              v-if="planModalState.hospitalFilter.length === 0"
              class="dropdown-item"
            >
              暂无搜索结果，请完整输入或选择其他
            </div>
            <div
              @click="onHospitalDropdown({ id: '0', value: '其他' })"
              class="dropdown-item"
            >
              其他
            </div>
          </div>
        </div>
      </div>
      <div class="plan-row">
        <div class="row-name">科室</div>
        <div class="row-input">
          <input
            class="row-content row-field"
            @focus="onDepartInputFocus"
            @input="inputDepart"
            v-model.trim="planModalState.depart"
            type="text"
            placeholder="请选择科室"
            maxlength="50"
          />
          <img
            src="@/assets/images/icon/arrow-right-grey.png"
            class="row-arrow"
          />

          <div v-if="planModalState.isShowDepartDropdown" class="row-dropdown">
            <div
              v-for="item in planModalState.departFilterList"
              :key="item.id"
              @click="onDepartDropdown(item)"
              class="dropdown-item"
            >
              {{ item.value }}
            </div>

            <div
              v-if="planModalState.departFilterList.length === 0"
              class="dropdown-item"
            >
              关键词暂无结果，请重新输入！
            </div>
          </div>
        </div>
      </div>
      <!-- 新增   预计拜访总人数   v-model.trim="planModalState.depart"-->
      <div class="plan-row">
        <div class="row-name">预计拜访人数</div>
        <div class="row-input">
          <input
            class="row-content row-num"
            v-model.trim="planModalState.estimatesNumber"
            type="number"
            placeholder="请输入拜访人数"
            onkeyup="this.value=parseInt(this.value.replace(/[^\d]/g,''))|0"
            onafterpaste="this.value=parseInt(this.value.replace(/[^\d]/g,''))|0"
          />
          <img src="" class="row-arrow" />
        </div>
      </div>

      <div class="plan-footer">
        <div class="plan-txt">
          合规提示：<br />
          1. 单家医院单个科室发放品牌提示物数量不超过40件 <br />
          2. 品牌提示物使用请严格遵守合规要求
        </div>
        <div class="plan-btnWrap">
          <div @click="submitPlan" class="btn">提交</div>
          <div @click="hidePlanModal" class="btn btn-purple-border">返回</div>
        </div>
        <div class="plan-footer-delete" v-if="planModalState.mode === 'edit'">
          <div class="plan-footer-delete-txt" @click="onDeleteHospital">
            删除拜访计划
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 提交确认 -->
  <warn-modal v-model:isVisible="isShowWarn" @confirm="onSubmit">
    <div class="warn-title">确认提交订单</div>
    <div class="warn-txt">订单提交之后将无法更改</div>
  </warn-modal>
  <!-- 取消订单 -->
  <warn-modal v-model:isVisible="isShowCancel" @confirm="cancelOrder">
    <div class="warn-title">确认取消订单</div>
    <div class="warn-txt">订单取消之后，本次订单将会删除</div>
  </warn-modal>
  <!-- 已无库存 -->
  <warn-modal v-model:isVisible="isShowOutStock" :isShowBtn="false">
    <div class="warn-title">已无库存</div>
    <div class="warn-txt">
      <div class="warn-row">
        订单中的<span class="warn-name">{{ outStockGiftName }}</span
        >已无库存
      </div>
      <div class="warn-row">请返回购物车重新编辑</div>
    </div>
    <div @click="outStockBack" class="btn warn-btn">返回</div>
  </warn-modal>

  <ErrorPopup v-model:isVisible="isShowErrorPopup" :txt="errorTxt" />

  <!-- 订单提交成功 -->
  <div v-if="isShowSubmitSuccess" class="submitSuccess">
    <div class="submitSuccess-content">
      <img src="@/assets/images/icon/order.png" class="submitSuccess-icon" />
      <div class="submitSuccess-title">订单提交成功！</div>
      <div class="submitSuccess-txt">
        *如在兑换或收货途中有问题，<br />
        请联系: 客服热线 <span>4008199900</span>
      </div>
      <div @click="toHistory" class="btn">我知道了</div>
    </div>
  </div>

  <van-popup
    v-model:show="planModalState.isShowCityPicker"
    round
    position="bottom"
  >
    <van-picker
      class="resetVan"
      title="请选择医院所在省/市"
      :columns="planModalState.areaList"
      @cancel="planModalState.isShowCityPicker = false"
      @confirm="cityPickerConfirm"
    />
  </van-popup>

  <ProductModal
    v-model:isVisible="isShowProductModal"
    :gift-id="currentGiftId"
    :spec1="spec1"
    :spec2="spec2"
    :default-num="currentNum"
    type="submitOrder"
    @update-gift="updateGift"
  />
</template>

<script src="./SubmitOrder.js"></script>

<style scoped lang="scss" src="./SubmitOrder.scss"></style>

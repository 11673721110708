// 拜访发放计划弹窗相关
import { computed, reactive } from "vue";
import { getCityListApi, getHospitalListApi, getDepartListApi } from "@/api";
import { defaultError } from "@/utils/common.js";
import debounce from "lodash/debounce";

const planModalState = reactive({
  isShowPlanModal: true,
  province: "",
  city: "",
  isShowCityPicker: false,
  areaList: [], //省/市
  hospital: "",
  hospitalId: "",
  hospitalList: [],
  hospitalFilter: [],
  isShowHospitalDropdown: false, // 医院的dropdown
  depart: "",
  departList: [], //完整的科室数据
  departFilterList: [], //显示的科室数据
  departId: "",
  isShowDepartDropdown: false,
  selectedHospitalList: [], // 选择的医院科室
  estimatesNumber: "", // 新增  预计拜访总人数
  mode: "create", // 新增创建还是编辑
  editIndex: -1, // 编辑索引
});

export default function planModalFunc({ toast }) {
  const initPlanModal = () => {
    planModalState.province = "";
    planModalState.city = "";
    planModalState.hospital = "";
    planModalState.hospitalId = "";
    planModalState.hospitalList = [];
    planModalState.hospitalFilter = [];
    planModalState.depart = "";
    planModalState.departId = "";
    planModalState.mode = "create";
    planModalState.estimatesNumber = "";
    planModalState.departFilterList = [...planModalState.departList];
    planModalState.editIndex = -1;
  };

  const onCreateHospital = () => {
    planModalState.mode = "create";
    showPlanModal();
  };

  const onEditHospital = (index) => {
    if (index >= planModalState.selectedHospitalList.length) {
      return;
    }
    const selectedHospital = planModalState.selectedHospitalList[index];
    if (!selectedHospital) {
      return;
    }

    planModalState.province = selectedHospital.province;
    planModalState.city = selectedHospital.city;
    planModalState.hospital = selectedHospital.hospital;
    planModalState.hospitalId = selectedHospital.hospitalId;
    planModalState.depart = selectedHospital.depart;
    planModalState.departId = selectedHospital.depart;
    planModalState.mode = "edit";
    planModalState.estimatesNumber = selectedHospital.estimatesNumber;
    planModalState.editIndex = index;
    getHospitalList();
    planModalState.isShowPlanModal = true;
  };

  async function getCityList() {
    let res = await getCityListApi();

    if (res.err === 0) {
      let provinceList = [];
      let cityList = [];
      Object.keys(res.data[1].childs).forEach((cityKey) => {
        cityList.push({
          id: cityKey,
          text: res.data[1].childs[cityKey],
        });
      });
      Object.keys(res.data[0]).forEach((provinceKey) => {
        const children = cityList.filter(
          (c) => c.id.substr(0, 2) === provinceKey.substr(0, 2)
        );
        provinceList.push({
          id: provinceKey,
          text: res.data[0][provinceKey],
          children,
        });
      });

      planModalState.areaList = provinceList;
    } else {
      toast({
        message: res.msg || defaultError,
      });
    }
  }

  async function getDepartList() {
    const res = await getDepartListApi();

    if (res.data && res.data.list) {
      planModalState.departList = res.data.list.map((x) => {
        return {
          id: x.id,
          value: x.name,
        };
      });

      planModalState.departFilterList = planModalState.departList;
    }
  }

  const showPlanModal = () => {
    planModalState.isShowPlanModal = true;
  };
  const hidePlanModal = () => {
    planModalState.isShowPlanModal = false;
  };

  const cityPickerConfirm = (valArr) => {
    const province = valArr[0].text;
    const city = valArr[1].text;
    if (province !== planModalState.province || city !== planModalState.city) {
      planModalState.hospital = "";
      planModalState.hospitalId = "";
      planModalState.isShowHospitalDropdown = false;
    }

    planModalState.province = province;
    planModalState.city = city;
    planModalState.isShowCityPicker = false;
    getHospitalList();
  };

  const isHospitalDisabled = computed(() => {
    if (planModalState.province === "" || planModalState.city === "") {
      return true;
    } else {
      return false;
    }
  });
  const checkHospitalAvailable = () => {
    if (isHospitalDisabled.value) {
      toast({
        duration: 1500,
        message: "请先选择省市",
      });
    }
  };
  const getHospitalList = async () => {
    let res = await getHospitalListApi({
      province: planModalState.province,
      city: planModalState.city,
      hospitalName: "",
    });

    if (res.data && res.data.list) {
      planModalState.hospitalList = res.data.list
        .filter((r) => r.hospitalName !== "其他")
        .map((x) => {
          return {
            id: x.id,
            value: x.hospitalName,
          };
        });

      // planModalState.isShowHospitalDropdown = true
    }
  };
  const getHospitalListFilter = () => {
    if (!planModalState.hospital && planModalState.hospital.length === 0) {
      planModalState.hospitalFilter = [];
      planModalState.isShowHospitalDropdown = false;
      return;
    }
    planModalState.hospitalFilter = planModalState.hospitalList.filter(
      (x) => x.value.indexOf(planModalState.hospital) >= 0
    );
    planModalState.isShowHospitalDropdown = true;
  };
  const inputHospital = debounce(() => {
    if (planModalState.hospital) {
      getHospitalListFilter();
    }
  }, 500);
  const onHospitalDropdown = (item) => {
    planModalState.hospital = item.value;
    planModalState.hospitalId = item.id;
    planModalState.isShowHospitalDropdown = false;
  };

  const onDepartInputFocus = () => {
    planModalState.isShowDepartDropdown = true;
  };
  const onDepartDropdown = (item) => {
    planModalState.depart = item.value;
    planModalState.departId = item.id;
    planModalState.isShowDepartDropdown = false;
  };
  const inputDepart = () => {
    planModalState.departFilterList = planModalState.departList.filter(
      (x) => x.value.indexOf(planModalState.depart) > -1
    );
  };
  const onDeleteHospital = () => {
    if (planModalState.editIndex >= 0) {
      planModalState.selectedHospitalList.splice(planModalState.editIndex, 1);
    }
    initPlanModal();
    hidePlanModal();
  };

  const submitPlan = () => {
    if (planModalState.province === "" || planModalState.city === "") {
      toast({
        duration: 1500,
        message: "请选择省市",
      });
      return;
    }
    if (!planModalState.hospital) {
      toast({
        duration: 1500,
        message: "请输入医院名称",
      });
      return;
    }
    if (!planModalState.depart) {
      toast({
        duration: 1500,
        message: "请选择科室",
      });
      return;
    }
    if (!planModalState.estimatesNumber) {
      toast({
        duration: 1500,
        message: "请选择拜访人数",
      });
      return;
    } else if (planModalState.estimatesNumber > 40) {
      toast({
        duration: 1500,
        message: "单家医院单个科室发放品牌提示物数量不超过40件",
      });
      return;
    }

    if (planModalState.mode === "create") {
      // 新建，检查是否有相同医院
      const hasOne = planModalState.selectedHospitalList.some(
        (x) => x.hospital === planModalState.hospital
      );
      if (hasOne) {
        toast({
          duration: 1500,
          message: "您已经选择了该医院",
        });
        return;
      }
      planModalState.selectedHospitalList.push({
        province: planModalState.province,
        city: planModalState.city,
        hospital: planModalState.hospital,
        depart: planModalState.depart,
        hospitalId: planModalState.hospitalId,
        departId: planModalState.departId,
        estimatesNumber: planModalState.estimatesNumber,
      });
    } else {
      // 编辑，检查是否有相同医院
      const hospital =
        planModalState.selectedHospitalList[planModalState.editIndex];
      if (!hospital) {
        return;
      }
      let otherHospitals = [...planModalState.selectedHospitalList];
      otherHospitals.splice(planModalState.editIndex, 1);
      const hasOne = otherHospitals.some(
        (x) => x.hospital === planModalState.hospital
      );
      if (hasOne) {
        toast({
          duration: 1500,
          message: "您已经选择了该医院",
        });
        return;
      }

      hospital.province = planModalState.province;
      hospital.city = planModalState.city;
      hospital.hospital = planModalState.hospital;
      hospital.depart = planModalState.depart;
      hospital.hospitalId = planModalState.hospitalId;
      hospital.departId = planModalState.departId;
      hospital.estimatesNumber = planModalState.estimatesNumber;
    }

    initPlanModal();
    hidePlanModal();
  };
  const hasPlan = computed(() => {
    return planModalState.selectedHospitalList.length > 0;
  });

  const totalEstimatesNumber = computed(() => {
    let totalCount = 0;
    planModalState.selectedHospitalList.forEach((item) => {
      let count = parseInt(item.estimatesNumber);
      if (!isNaN(count)) {
        totalCount += count;
      }
    });
    return totalCount;
  });

  return {
    planModalState,
    hasPlan,
    totalEstimatesNumber,
    getCityList,
    getDepartList,
    showPlanModal,
    hidePlanModal,
    cityPickerConfirm,
    isHospitalDisabled,
    checkHospitalAvailable,
    inputHospital,
    onHospitalDropdown,
    onDepartInputFocus,
    onDepartDropdown,
    onDeleteHospital,
    inputDepart,
    submitPlan,
    onEditHospital,
    onCreateHospital
  };
}

import { reactive, toRefs, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { Picker, Popup } from "vant";
import WarnModal from "@/components/WarnModal";
import ErrorPopup from "@/components/ErrorPopup";
import ProductModal from "@/components/ProductModal/ProductModal.vue";

import planModalFunc from "./planModal";

import { getAddressApi } from "@/api/address";
import { submitOrderApi } from "@/api/order";
import { defaultError, formatPhone } from "@/utils/common.js";
import { SET_ORDER_ADDRESS, SET_ORDER_GIFTS } from "@/store/mutation-types.js";
import { checkPoints } from "@/use/settlement";

export default {
  name: "SubmitOrder",
  components: {
    WarnModal,
    ErrorPopup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    ProductModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = inject("toast");
    const loading = inject("loading");

    const state = reactive({
      isFold: true,
      isShowProductModal: false,
      currentGiftId: 0,
      spec1: "", //当前编辑的产品的规格，sku用
      spec2: "",
      currentNum: 0, //当前编辑的产品的数量，sku用
      currentIndex: -1, //当前编辑的产品
      isShowWarn: false, //提交确认弹窗
      isShowCancel: false,
      isShowOutStock: false,
      outStockGiftName: "",
      isShowErrorPopup: false,
      errorTxt: "",
      isShowSubmitSuccess: false,
      isSubmitLoading: false,
    });

    let {
      planModalState,
      totalEstimatesNumber,
      hasPlan,
      getCityList,
      getDepartList,
      showPlanModal,
      hidePlanModal,
      cityPickerConfirm,
      isHospitalDisabled,
      checkHospitalAvailable,
      inputHospital,
      onHospitalDropdown,
      onDepartInputFocus,
      onDepartDropdown,
      inputDepart,
      submitPlan,
      onDeleteHospital,
      onEditHospital,
      onCreateHospital,
    } = planModalFunc({ toast });

    const isFullScreen = computed(() => store.state.isFullScreen);
    const expressFee = computed(() => store.state.expressFee);

    onMounted(async () => {
      loading.show();
      await Promise.all([getAddress(), getCityList()]);
      loading.hide();
      getDepartList();
    });

    const orderAdress = computed(() => store.state.orderAdress);
    async function getAddress() {
      if (orderAdress.value.id) return;

      const res = await getAddressApi();

      if (res.code === 0) {
        let address = res.data.find((x) => x.isDefault);
        if (address) {
          address.formatPhone = formatPhone(address.telephone);
          store.commit(SET_ORDER_ADDRESS, address);
        }
      } else {
        toast({
          message: res.message || defaultError,
        });
      }
    }
    const toAddressList = () => {
      router.push({ name: "AddressList" });
    };

    const orderGifts = computed(() => {
      return store.state.orderGifts.map((x) => {
        x.totalPrice = x.price * x.count;
        x.specString = `${x.attributeRecord1Value || ""} ${
          x.attributeRecord2Value || ""
        }`;
        return x;
      });
    });
    const orderGiftsFilter = computed(() => {
      if (state.isFold) {
        return orderGifts.value.slice(0, 2);
      } else {
        return orderGifts.value;
      }
    });

    const showProductModal = (index) => {
      const currentGift = orderGifts.value[index];
      state.currentIndex = index;
      state.currentGiftId = currentGift.giftId;
      state.spec1 = currentGift.attributeRecord1Value;
      state.spec2 = currentGift.attributeRecord2Value;
      state.currentNum = currentGift.count;

      state.isShowProductModal = true;
    };
    // 编辑礼品后，更新数据
    const updateGift = (giftParams) => {
      let copyOrderGifts = JSON.parse(JSON.stringify(orderGifts.value));
      copyOrderGifts[state.currentIndex] = {
        ...copyOrderGifts[state.currentIndex],
        ...giftParams,
      };
      store.commit(SET_ORDER_GIFTS, copyOrderGifts);
    };
    const toggleMore = () => {
      state.isFold = !state.isFold;
    };

    const myPoints = computed(() => store.state.myPoints);
    // 礼品合计积分
    const giftsTotalPrice = computed(() => {
      let price = 0;
      orderGifts.value.map((x) => {
        price += x.totalPrice;
      });

      return price;
    });
    // 合计积分 = 礼品合计积分 + 运费
    const totalPrice = computed(() => giftsTotalPrice.value + expressFee.value);
    // 礼品合计总数
    const totalGiftCounts = computed(() => {
      let count = 0;
      orderGifts.value.map((x) => {
        const num = parseInt(x.count + "");
        if (!isNaN(num)) {
          count += num;
        }
      });
      return count;
    });

    const onCancel = () => {
      state.isShowCancel = true;
    };
    const cancelOrder = () => {
      router.replace({ name: "Mall" });
    };

    const checkSubmit = () => {
      // 检查拜访计划
      if (!hasPlan.value) {
        state.isShowErrorPopup = true;
        state.errorTxt = "您尚未添加拜访发放计划，请补充";
        return;
      } else {
        // 如果礼品数量大于拜访人数，不能提交
        if (totalGiftCounts.value > totalEstimatesNumber.value) {
          state.isShowErrorPopup = true;
          state.errorTxt = "合规提示：品牌提示物数量不得大于计划拜访人数";
          return;
        }
      }
      // 检查积分
      const checkPointsRes = checkPoints(giftsTotalPrice.value, myPoints.value);
      if (!checkPointsRes.success) {
        state.isShowErrorPopup = true;
        state.errorTxt = checkPointsRes.error;
        return;
      }

      state.isShowWarn = true;
    };
    const onSubmit = async () => {
      if (state.isSubmitLoading) {
        return;
      }
      state.isSubmitLoading = true;
      let giftReceiveSubmitViewModels = orderGifts.value.map((x) => {
        return {
          activityCode: store.state.code,
          giftId: x.giftId,
          shoppingCartId: x.shoppingCartId || 0,
          qty: x.count,
          attributeRecord1Id: x.attributeRecord1Id,
          attributeRecord2Id: x.attributeRecord2Id,
          province: orderAdress.value.province,
          city: orderAdress.value.city,
          district: orderAdress.value.district,
          street: orderAdress.value.street,
          consignee: orderAdress.value.consignee,
          telephone: orderAdress.value.telephone,
        };
      });
      let hospitalSubmitViews = planModalState.selectedHospitalList.map((x) => {
        return {
          hospitalId: x.hospitalId,
          departmentId: x.departId,
          estimatesNumber: parseInt(x.estimatesNumber),
        };
      });
      const requestData = {
        giftReceiveSubmitViewModels,
        hospitalSubmitViews,
      };
      loading.show();
      const res = await submitOrderApi(requestData);
      loading.hide();
      state.isSubmitLoading = false;
      if (res.code === 6) {
        return;
      }

      if (res.code === 0) {
        state.isShowWarn = false;
        state.isShowSubmitSuccess = true;
      } else if (res.code === 10) {
        // 有礼品没库存
        state.outStockGiftName = orderGifts.value.find(
          (x) => Number(x.giftId) === Number(res.message)
        ).giftName;

        state.isShowWarn = false;
        state.isShowOutStock = true;
      } else {
        toast({
          message: res.message || defaultError,
        });
      }
    };

    const toHistory = () => {
      router.replace({ name: "History" });
    };

    const outStockBack = () => {
      router.go(-1);
    };

    return {
      ...toRefs(state),
      isFullScreen,
      planModalState,
      hasPlan,
      totalEstimatesNumber,
      showPlanModal,
      hidePlanModal,
      cityPickerConfirm,
      checkHospitalAvailable,
      isHospitalDisabled,
      inputHospital,
      onHospitalDropdown,
      onDepartInputFocus,
      onDepartDropdown,
      inputDepart,
      onDeleteHospital,
      onCreateHospital,
      onEditHospital,
      submitPlan,
      cancelOrder,
      orderAdress,
      orderGifts,
      orderGiftsFilter,
      showProductModal,
      toggleMore,
      updateGift,
      myPoints,
      totalPrice,
      expressFee,
      totalGiftCounts,
      onCancel,
      checkSubmit,
      onSubmit,
      toHistory,
      outStockBack,
      toAddressList,
    };
  },
};

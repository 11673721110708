import { request } from '@/utils/request'
// 订单相关api
// 提交订单、查询到的等

/**
 * 提交订单
 */
 export function submitOrderApi(data) {
  return request({
    method: 'post',
    url: '/GiftReceive/GiftReceiveList',
    data,
  })
}

/**
 * 获取兑换记录
 */
 export function getBoolingListApi(data) {
  return request({
    method: 'post',
    url: '/GiftReceive/GetBoolingList',
    data,
  })
}

/**
 * 订单详情
 * @param bookingId - 订单号
 */
 export function getBookingDetailApi(data) {
  return request({
    method: 'post',
    url: '/GiftReceive/GetBookingDetail',
    data,
  })
}

/**
 * 查询物流明细
 * @param logisticsCompany - 物流公司Code
 * @param logisticsNum - 运单号
 */
 export function getLogisticsInforApi(data) {
  return request({
    method: 'post',
    url: '/LogisticsInformation/GetLogisticsInformation',
    data,
  })
}



<template>
  <teleport v-if="isVisible" to="body">
    <div class="warn-modal">
      <div class="warn-content">
        <img src="@/assets/images/warn.png" class="warn-img">

        <slot></slot>

        <div v-if="isShowBtn" class="warn-btnWrap">
          <div @click="confirm" class="warn-btn">确定</div>
          <div @click="hideWarn" class="warn-btn warn-cancel">取消</div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>

export default {
  name: 'WarnModal',
  emits: ['update:isVisible', 'confirm'],
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    isShowBtn: {
      type: Boolean,
      default: true
    },
  },
  setup(props, { emit }) {
    const hideWarn = () => {
      emit('update:isVisible', false)
    }
    const confirm = () => {
      emit('confirm')
    }

    return {
      hideWarn,
      confirm,
    }
  },
} 
</script>

<style scoped lang="scss">
.warn {
  &-modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.53);
  }
  &-content {
    width: 610px;
    padding: 51px 0;
    background-color: #fff;
    border-radius: 15px;
  }
  &-img {
    display: block;
    width: 164px;
    margin: 0 auto;
  }
  &-btnWrap {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    grid-column-gap: 69px;
  }
  &-btn {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 222px;
    height: 100px;
    font-size: 32px;
    line-height: 42px;
    color: #fff;
    border-radius: 100px;
    background-color: #8c0e85;
  }
  &-cancel {
    color: #8c0e85;
    background-color: #fff;
    border: 3px solid #8c0e85;
  }
}
</style>